import s from './BeforeAfterV2.module.scss';
import before_female from '../../img/paywall/before_female.jpg';
import after_female from '../../img/paywall/after_female.jpg';
import before_male from '../../img/paywall/before_male.jpg';
import after_male from '../../img/paywall/after_male.jpg';
import arrows_right from '../../img/paywall/arrows_right.png';
import cn from 'classnames';
import { useAnswers } from '../../helpers/AnswersContext';

const BeforeAfterV2 = () => {
  const { answers } = useAnswers();
  const isMaleGender = answers.gender === 'male';
  const renderImageBefore = isMaleGender ? before_male : before_female;
  const renderImageAfter = isMaleGender ? after_male : after_female;

  return (
    <section>
      <div className={s.beforeAfterV2}>
        <div className={s.block_row}>
          <div className={s.block_column}>
            <div className={s.block_labelStats}>4 weeks ago</div>
            <div className={s.block}>
              <img
                className={s.block_img}
                src={renderImageBefore}
                alt='before'
              />
              <div className={s.block_label}>Before</div>
            </div>
            <div className={cn(s.block_labelStats, s.block_labelStats_fat)}>
              <b style={{ fontSize: '15px', fontWwight: '500' }}>Body fat</b>
              <div>24-29%</div>
            </div>
          </div>
          <img src={arrows_right} className={s.arrows} alt='arrows right'></img>
          <div className={s.block_column}>
            <div className={cn(s.block_labelStats, s.block_labelStats_after)}>
              Now
            </div>
            <div className={cn(s.block, s.after)}>
              <img className={s.block_img} src={renderImageAfter} alt='now' />
              <div className={s.block_label}>Now</div>
            </div>
            <div
              className={cn(s.block_labelStats, s.block_labelStats_fat_after)}
            >
              <b
                style={{
                  color: '#505050',
                  fontSize: '15px',
                  fontWwight: '500',
                }}
              >
                Body fat
              </b>
              <div className={s.block_labelStats_fat_after_arrow}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10.6859 3.08919C10.6618 2.92481 10.5791 2.77467 10.4531 2.66641C10.3271 2.55816 10.1662 2.49906 10 2.50002C9.90986 2.49936 9.82044 2.51647 9.73688 2.55037C9.65332 2.58427 9.57725 2.6343 9.51302 2.6976C9.4488 2.7609 9.39767 2.83623 9.36255 2.91929C9.32744 3.00235 9.30903 3.09151 9.30837 3.18169V15.1667L4.93254 10.8375L4.85504 10.7709C4.72141 10.6732 4.55752 10.6261 4.39246 10.6379C4.2274 10.6496 4.07183 10.7195 3.95337 10.835C3.88914 10.8978 3.83806 10.9727 3.80314 11.0554C3.76822 11.1381 3.75016 11.227 3.75 11.3168C3.74985 11.4065 3.76761 11.4955 3.80224 11.5783C3.83687 11.6611 3.88769 11.7362 3.95171 11.7992L9.50004 17.29C9.5644 17.3558 9.6411 17.4083 9.72575 17.4444C9.81041 17.4805 9.90135 17.4996 9.99338 17.5005C10.0854 17.5014 10.1767 17.4842 10.2621 17.4498C10.3475 17.4154 10.4252 17.3645 10.4909 17.3L16.0492 11.8L16.1159 11.7225C16.214 11.5914 16.2611 11.429 16.2483 11.2658C16.2356 11.1025 16.1639 10.9494 16.0467 10.835C15.9157 10.7071 15.7397 10.6358 15.5566 10.6362C15.3735 10.6367 15.1979 10.709 15.0675 10.8375L10.6925 15.1675V3.18169L10.6859 3.08919Z'
                    fill='#509B04'
                  />
                </svg>
                14-19%
              </div>
            </div>
          </div>
        </div>
        <div className={s.block_text}>
          <div className={s.beforeAfterV2_description}>
            *Results are not typical. Individual results may vary
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeforeAfterV2;
