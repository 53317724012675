import React, { createContext, useContext, useState } from 'react';

const AnswersContext = createContext();

export const AnswersProvider = ({ children }) => {
  const [answers, setAnswers] = useState({});

  const saveAnswers = newAnswers => {
    setAnswers(newAnswers);
  };

  return (
    <AnswersContext.Provider value={{ answers, saveAnswers }}>
      {children}
    </AnswersContext.Provider>
  );
};

export const useAnswers = () => {
  return useContext(AnswersContext);
};
