import React from 'react';
import s from './MainGoalV2.module.scss';

const MainGoalV2 = props => {
  const {
    mainGoal = { variants: ['Lose weight'], icons: ['quest_icon_darts'] },
    goalWeight = { value: 70, scale: 'SI' },
  } = props;

  return (
    <>
      <div className={s.mainGoalV2_mainTitle}>Choose your plan</div>
      <div className={s.mainGoalV2}>
        <div className={s.leftPart}>
          <img
            src={require(`../../img/questionIcons/${mainGoal.icons[0]}.png`)}
            className={s.mainGoalV2_icon}
            alt='mainGoal'
          />
          <div className={s.mainGoalV2_text}>
            <div className={s.mainGoalV2_title}>Main goal</div>
            <div>{mainGoal.variants[0]}</div>
          </div>
        </div>
        <div className={s.divider}></div>
        <div className={s.leftPart}>
          <div className={s.mainGoalV2_text}>
            <div className={s.mainGoalV2_title}>Target weight</div>
            <div>
              {goalWeight.value} {goalWeight.scale === 'SI' ? 'kg' : 'lbs'}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainGoalV2;
