import React, { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import s from './CheckoutForm.module.scss';
import Loader from '../Loader/Loader';
import ReactPixel from "react-facebook-pixel";

const CheckoutForm = ({ onClose, description, clientSecret }) => {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements || !clientSecret) {
      return;
    }

    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: `${process.env.REACT_APP_DOMAIN}/thanks`,
        },
      })
      .then(result => {
        if (result.error) {
          console.log(result.error.message);
          setIsLoading(false);
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            ReactPixel.track('Purchase', {
              content_type: 'subscription',
              content_ids: ['subscription'],
              value: 4.99, currency: 'USD'
            });
            setIsLoading(false);
          }
        }
      });
  };

  return (
    <div className={s.overlay}>
      <div className={s.modal}>
        {isLoading && <Loader />}
        <form onSubmit={handleSubmit}>
          <button type='button' className={s.close} onClick={onClose}></button>
          <PaymentElement />
          <div>
            <button className={s.payButton} type='submit' disabled={!stripe}>
              Pay
            </button>
            <div className={s.footer_description}>{description}</div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;
