/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { quizData } from '../../quizData/quizData';
import { useNavigate } from 'react-router-dom';
import s from './Quiz.module.scss';
import Title from '../../components/Title/Title';
import cn from 'classnames';
import { useAnswers } from '../../helpers/AnswersContext';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import CurrentWeight from '../CurrentWeight/CurrentWeight';
import GoalWeight from '../GoalWeight/GoalWeight';
import TimeFirstMeal from '../TimeFirstMeal/TimeFirstMeal';
import TimeLastMeal from '../TimeLastMeal/TimeLastMeal';
import Height from '../Height/Height';
import security from '../../img/firstScreen/security.png';

const Quiz = () => {
  const [step, setStep] = useState(0);
  const [showQuiz, setShowQuiz] = useState(false);
  const [pointerEvents, setPointerEvents] = useState(true);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [height, setHeight] = useState('');
  const [heightUnit, setHeightUnit] = useState('');
  const [currentWeight, setCurrentWeight] = useState('');
  const [goalWeight, setGoalWeight] = useState('');
  const [weightUnit, setWeightUnit] = useState('Lbs');
  const [timeFirstMeal, setTimeFirstMeal] = useState('am');
  const [timeLastMeal, setTimeLastMeal] = useState('pm');
  const [timeUnit, setTimeUnit] = useState('am');
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(0);
  const [keyboardHeight, setKeyboardHeight] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { answers, saveAnswers } = useAnswers();
  const question = quizData[step];
  const weightQuestion =
    question.currentWeight || question.goalWeight || question.height;
  const fastingPlanQuestion = question.timeFirstMeal || question.timeLastMeal;
  const navigate = useNavigate();
  const visualViewport = useRef(window.visualViewport);
  const unitAnswer = answers.currentWeight?.scale === 'imperial' ? 'lbs' : 'kg';
  const isUnitLbs = unitAnswer === 'lbs';
  const currentWeightAnswer = answers.currentWeight;
  const goalWeightAnswer = answers.goalWeight;
  const isWhenResultVariant = question.name === 'whenResults';

  const targetWeightDifferent =
    currentWeightAnswer && goalWeightAnswer
      ? Number(currentWeightAnswer.value) - Number(goalWeightAnswer.value)
      : 0;

  const weeksWhenGoalHandler = (variantsValue, targetWeightDifferent) => {
    return variantsValue
      ? Math.abs(Math.round(targetWeightDifferent / variantsValue))
      : 32;
  };

  const variantsValue = index => {
    return isUnitLbs
      ? question?.variantsValuesLbs[index].toFixed(1)
      : question?.variantsValuesKg[index];
  };

  console.log('targetWeightDifferent:', targetWeightDifferent);
  console.log('answers:', answers);

  const currentDate = new Date().toISOString();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowQuiz(true);
      setPointerEvents(false);
    }, 100);

    const handleResize = () => {
      const keyboardHeight = Math.floor(
        window.innerHeight - visualViewport.current.height
      );
      setScrollPosition(window.scrollY);
      setKeyboardHeight(keyboardHeight);
      if (visualViewport.current) {
        if (visualViewport.current.height < window.innerHeight) {
          setIsKeyboardOpen(true);
        } else {
          setIsKeyboardOpen(false);
        }
      }
    };

    visualViewport.current.addEventListener('resize', handleResize);

    setScrollPosition(window.scrollY);
    restoreScrollPosition();

    return () => {
      clearTimeout(timeoutId);
      visualViewport.current.removeEventListener('resize', handleResize);
    };
  }, [step, keyboardHeight]);

  useEffect(() => {
    nextButtonDisabledHandler();
  }, [isKeyboardOpen]);

  const restoreScrollPosition = () => {
    window.scrollTo(0, scrollPosition);
  };

  const onClickVariant = index => {
    const isSelected = selectedVariants.includes(index);
    const updatedVariants = isSelected
      ? selectedVariants.filter(item => item !== index)
      : [...selectedVariants, index];

    setSelectedVariants(updatedVariants);

    if (!question.nextButton) {
      setPointerEvents(true);
      setTimeout(() => {
        setShowQuiz(false);
      }, 300);
      const newAnswers = {
        ...answers,
        [question.name]: {
          variants: isWhenResultVariant
            ? weeksWhenGoalHandler(variantsValue(index), targetWeightDifferent)
            : updatedVariants.map(index => question.variants[index]),
          ...(question.icons && {
            icons: updatedVariants.map(index => question.icons[index]),
          }),
          ...(question.variantsValuesKg && {
            variantsValues: updatedVariants.map(index =>
              isUnitLbs
                ? Number(question.variantsValuesLbs[index]).toFixed(1)
                : Number(question.variantsValuesKg[index])
            ),
            variantsDifficulty: updatedVariants.map(
              index => question.variantsDifficulty[index]
            ),
          }),
        },
      };
      saveAnswers(newAnswers);

      setTimeout(() => {
        setStep(prev => prev + 1);
        // step !== quizData.length - 1 || navigate('/preload'); TODO: вернуть result
        step !== quizData.length - 1 || navigate('/result');
        setSelectedVariants([]);
        // navigate('/result');
      }, 500);
    }
  };

  const onInputChange = (value, unit) => {
    if (question.currentWeight) {
      setCurrentWeight(value);
      setWeightUnit(unit);
    } else if (question.goalWeight) {
      setGoalWeight(value);
      setWeightUnit(unit);
    } else if (question.height) {
      setHeight(value);
      setHeightUnit(unit);
    }
    nextButtonDisabledHandler();
  };

  const onTimeChange = (newTime, newTimeUnit) => {
    if (question.timeFirstMeal) {
      setTimeFirstMeal(newTime);
      setTimeUnit(newTimeUnit);
    } else if (question.timeLastMeal) {
      setTimeLastMeal(newTime);
      setTimeUnit(newTimeUnit);
    }
    nextButtonDisabledHandler();
  };

  const nextButtonHandler = () => {
    setIsNextBtnDisabled(true);

    let newAnswers;
    if (selectedVariants.length > 0 && !question.withImage) {
      newAnswers = {
        ...answers,
        [question.name]: {
          name: question.name,
          variants: selectedVariants.map(index => question.variants[index]),
          ...(question.icons && {
            icons: selectedVariants.map(index => question.icons[index]),
          }),
        },
      };
    } else if (
      ['currentWeight', 'goalWeight', 'height'].includes(question.name)
    ) {
      newAnswers = {
        ...answers,
        [question.name]: {
          value: Number(
            question.name === 'height'
              ? height
              : question.name === 'currentWeight'
              ? currentWeight
              : goalWeight
          ),
          scale:
            (question.name === 'height' ? heightUnit : weightUnit) === 'Lbs'
              ? 'imperial'
              : 'SI',
          measuredAt: currentDate,
        },
      };
    } else if (['timeFirstMeal', 'timeLastMeal'].includes(question.name)) {
      newAnswers = {
        ...answers,
        [question.name]: {
          value:
            question.name === 'timeFirstMeal' ? timeFirstMeal : timeLastMeal,
          unit: timeUnit,
        },
      };
    }

    if (newAnswers) {
      saveAnswers(newAnswers);
    }

    setStep(prev => prev + 1);
    setSelectedVariants([]);

    if (step === quizData.length - 1 || question.name === 'timeLastMeal') {
      navigate('/preload');
    }
  };

  const nextButtonDisabledHandler = () => {
    if (
      question.nextButton &&
      [
        'currentWeight',
        'goalWeight',
        'timeFirstMeal',
        'timeLastMeal',
        'height',
      ].includes(question.name)
    ) {
      const value =
        question.name === 'currentWeight'
          ? currentWeight
          : question.name === 'goalWeight'
          ? goalWeight
          : question.name === 'timeFirstMeal'
          ? timeFirstMeal
          : question.name === 'timeLastMeal'
          ? timeLastMeal
          : height;

      setIsNextBtnDisabled(
        typeof value === 'string' && value.trim() === '' && value !== '0'
      );
    }
  };

  const withNextButton = () => {
    if (question.nextButton) {
      const disabledClass = () => {
        if (weightQuestion && isNextBtnDisabled) {
          return s.disabled;
        } else if (fastingPlanQuestion && isNextBtnDisabled) {
          return s.disabled;
        } else if (
          !weightQuestion &&
          !fastingPlanQuestion &&
          selectedVariants.length === 0
        ) {
          return s.disabled;
        }
      };

      const disabledState = () => {
        if (weightQuestion && isNextBtnDisabled) {
          return true;
        } else if (fastingPlanQuestion && isNextBtnDisabled) {
          return true;
        } else if (
          !weightQuestion &&
          !fastingPlanQuestion &&
          selectedVariants.length === 0
        ) {
          return true;
        }
      };

      return (
        <div className={s.next_button_bg}>
          <button
            onClick={() => nextButtonHandler()}
            style={{ bottom: isKeyboardOpen ? keyboardHeight + 20 : '' }}
            className={cn(s.next_button, disabledClass())}
            disabled={disabledState()}
          >
            {step !== quizData.length - 1 ? 'Continue' : 'Get personal plan'}
          </button>
        </div>
      );
    }
    return null;
  };

  const stepBack = () => {
    setIsNextBtnDisabled(true);
    if (step === 0) {
      navigate('/');
    }
    if (step > 0) {
      setStep(prev => prev - 1);
      setSelectedVariants([]);
    }
  };

  const percentage = Math.round((step / quizData.length) * 100);

  const renderImage = () => {
    if (question.img) {
      return (
        <div className={cn(s.img_container, s.fade, showQuiz ? s.show : '')}>
          <img src={require(`../../img/${question.img}.png`)} alt='' />
        </div>
      );
    }
    return null;
  };

  const withDescription = () => {
    if (question.description) {
      return <p className={cn(s.description)}>{question.description}</p>;
    }
    return null;
  };

  const defaultVariant = () => {
    return (
      <ul className={cn(s.fade, showQuiz ? s.show : '')}>
        {question.variants &&
          question.variants.map((quest, index) => (
            <li
              className={selectedVariants.includes(index) ? s.checked : ''}
              key={quest + step + index}
              onClick={() => onClickVariant(index)}
            >
              <div className={s.quest_textAndIcon}>
                {question.icons && (
                  <img
                    className={s.quest_icon}
                    src={require(`../../img/questionIcons/${question.icons[index]}.png`)}
                    alt='icon'
                  />
                )}
                <div className={s.quest_textWithDescription}>
                  {
                    <div
                      className={cn(
                        question.variantsDescription &&
                          s.quest_textWithDescription_bold
                      )}
                    >
                      {quest}
                    </div>
                  }
                  {question.variantsDescription && (
                    <div className={s.quest_textWithDescription_description}>
                      {question.variantsDescription[index]}
                    </div>
                  )}
                </div>
              </div>
              {!question.nextButton && (
                <div className={cn(s.radio)}>
                  {selectedVariants.includes(index)}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='8'
                    height='16'
                    viewBox='0 0 8 16'
                    fill='none'
                  >
                    <path
                      d='M0.0520926 1.00509C0.0523262 1.27022 0.157835 1.5244 0.345425 1.71176L5.46009 6.82643C5.6149 6.9812 5.73771 7.16495 5.82149 7.36719C5.90528 7.56942 5.9484 7.78619 5.9484 8.00509C5.9484 8.224 5.90528 8.44076 5.82149 8.643C5.73771 8.84524 5.6149 9.02899 5.46009 9.18376L0.352092 14.2918C0.169934 14.4804 0.0691395 14.733 0.0714178 14.9952C0.0736961 15.2574 0.178865 15.5082 0.364274 15.6936C0.549682 15.879 0.800494 15.9842 1.06269 15.9864C1.32489 15.9887 1.57749 15.8879 1.76609 15.7058L6.87409 10.6018C7.56092 9.91358 7.94666 8.98103 7.94666 8.00876C7.94666 7.03649 7.56092 6.10393 6.87409 5.41576L1.75943 0.297759C1.61957 0.157815 1.44135 0.0625014 1.24731 0.0238786C1.05327 -0.0147441 0.852129 0.00506 0.669348 0.080785C0.486566 0.15651 0.330354 0.284752 0.220479 0.449285C0.110603 0.613817 0.0520029 0.807245 0.0520926 1.00509Z'
                      fill='#B2B1FF'
                    />
                  </svg>
                </div>
              )}
              {question.nextButton && (
                <div className={cn(s.radio, s.radio_marked)}>
                  {selectedVariants.includes(index) ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='26'
                      height='26'
                      viewBox='0 0 26 26'
                      fill='none'
                    >
                      <path
                        d='M8.39496 22.3817C7.65692 22.382 6.94912 22.0886 6.42769 21.5663L0.479982 15.6208C-0.159994 14.9806 -0.159994 13.9429 0.479982 13.3027C1.12016 12.6627 2.1579 12.6627 2.79808 13.3027L8.39496 18.8996L23.2019 4.09263C23.8421 3.45265 24.8798 3.45265 25.52 4.09263C26.16 4.73281 26.16 5.77055 25.52 6.41073L10.3622 21.5663C9.84079 22.0886 9.13299 22.382 8.39496 22.3817Z'
                        fill='white'
                      />
                    </svg>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </li>
          ))}
      </ul>
    );
  };

  const whenResultsVariant = () => {
    return (
      <ul className={cn(s.fade, showQuiz ? s.show : '')}>
        {question.variants &&
          question.variants.map((quest, index) => {
            return (
              <li
                className={selectedVariants.includes(index) ? s.checked : ''}
                key={quest + step + index}
                onClick={() => {
                  onClickVariant(index);
                }}
              >
                <div className={s.quest_textAndIcon}>
                  {question.icons && (
                    <img
                      className={s.quest_icon}
                      src={require(`../../img/questionIcons/${question.icons[index]}.png`)}
                      alt='icon'
                    />
                  )}
                  <div className={s.quest_textWithDescription}>
                    {
                      <div
                        className={cn(
                          question.variantsDescription &&
                            s.quest_textWithDescription_bold
                        )}
                      >
                        {weeksWhenGoalHandler(
                          variantsValue(index),
                          targetWeightDifferent
                        )}{' '}
                        {quest}
                      </div>
                    }
                    {question.variantsDescription && (
                      <div className={s.quest_textWithDescription_description}>
                        {variantsValue(index)} {unitAnswer}
                        {question.variantsDescription[index]}
                      </div>
                    )}
                  </div>
                </div>
                {!question.nextButton && (
                  <div className={cn(s.radio)}>
                    {selectedVariants.includes(index)}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='8'
                      height='16'
                      viewBox='0 0 8 16'
                      fill='none'
                    >
                      <path
                        d='M0.0520926 1.00509C0.0523262 1.27022 0.157835 1.5244 0.345425 1.71176L5.46009 6.82643C5.6149 6.9812 5.73771 7.16495 5.82149 7.36719C5.90528 7.56942 5.9484 7.78619 5.9484 8.00509C5.9484 8.224 5.90528 8.44076 5.82149 8.643C5.73771 8.84524 5.6149 9.02899 5.46009 9.18376L0.352092 14.2918C0.169934 14.4804 0.0691395 14.733 0.0714178 14.9952C0.0736961 15.2574 0.178865 15.5082 0.364274 15.6936C0.549682 15.879 0.800494 15.9842 1.06269 15.9864C1.32489 15.9887 1.57749 15.8879 1.76609 15.7058L6.87409 10.6018C7.56092 9.91358 7.94666 8.98103 7.94666 8.00876C7.94666 7.03649 7.56092 6.10393 6.87409 5.41576L1.75943 0.297759C1.61957 0.157815 1.44135 0.0625014 1.24731 0.0238786C1.05327 -0.0147441 0.852129 0.00506 0.669348 0.080785C0.486566 0.15651 0.330354 0.284752 0.220479 0.449285C0.110603 0.613817 0.0520029 0.807245 0.0520926 1.00509Z'
                        fill='#B2B1FF'
                      />
                    </svg>
                  </div>
                )}
                {question.nextButton && (
                  <div className={cn(s.radio, s.radio_marked)}>
                    {selectedVariants.includes(index) ? (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='26'
                        height='26'
                        viewBox='0 0 26 26'
                        fill='none'
                      >
                        <path
                          d='M8.39496 22.3817C7.65692 22.382 6.94912 22.0886 6.42769 21.5663L0.479982 15.6208C-0.159994 14.9806 -0.159994 13.9429 0.479982 13.3027C1.12016 12.6627 2.1579 12.6627 2.79808 13.3027L8.39496 18.8996L23.2019 4.09263C23.8421 3.45265 24.8798 3.45265 25.52 4.09263C26.16 4.73281 26.16 5.77055 25.52 6.41073L10.3622 21.5663C9.84079 22.0886 9.13299 22.382 8.39496 22.3817Z'
                          fill='white'
                        />
                      </svg>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </li>
            );
          })}
      </ul>
    );
  };

  const withImageVariant = () => {
    if (question.withImage) {
      const isMaleGender = answers.gender === 'male' && question.maybeMale;

      const imageFileName = isMaleGender
        ? `${question.withImage}_male.png`
        : `${question.withImage}.png`;

      return (
        <div className={cn(s.withImage, s.fade, showQuiz ? s.show : '')}>
          <div>
            <img
              className={s.withImage_img}
              src={require(`../../img/questionImages/${imageFileName}`)}
              alt=''
            />
          </div>
          <Title
            fullText={question.title}
            markedText={[question.markedTitle]}
            markedColor='#7270FF'
          />
          <div className={s.withImage_text}></div>
          <div className={s.withImage_text}>{question.text}</div>
          <div className={s.withImage_text}>{question.text2}</div>
          <div className={s.withImage_text}>{question.text3}</div>
          <div className={s.next_button_bg}>
            <button
              onClick={() => nextButtonHandler()}
              className={cn(s.next_button)}
            >
              Continue
            </button>
          </div>
        </div>
      );
    }

    return null;
  };

  const isDefaultVariant = !question.withImage && !isWhenResultVariant;

  return (
    <>
      <ProgressBar percentage={percentage} stepBack={stepBack} />
      <div className={s.quiz_container}>
        <div className={cn(s.quiz, pointerEvents && s.preventClick)}>
          {!question.withImage && <Title fullText={question.title} />}
          {withDescription()}
          {renderImage()}
          {isDefaultVariant && defaultVariant()}
          {isWhenResultVariant && whenResultsVariant()}
          {withImageVariant()}
          {withNextButton()}
          {question.height && (
            <Height
              onInputChange={onInputChange}
              restoreScrollPosition={restoreScrollPosition}
            />
          )}
          {question.currentWeight && (
            <CurrentWeight
              onInputChange={onInputChange}
              restoreScrollPosition={restoreScrollPosition}
            />
          )}
          {question.goalWeight && (
            <GoalWeight
              onInputChange={onInputChange}
              unit={weightUnit}
              currentWeight={currentWeight}
              restoreScrollPosition={restoreScrollPosition}
            />
          )}
          {question.timeFirstMeal && (
            <TimeFirstMeal
              onTimeChange={onTimeChange}
              unit={timeUnit}
              currentTime={timeFirstMeal}
              restoreScrollPosition={restoreScrollPosition}
            />
          )}
          {question.timeLastMeal && (
            <TimeLastMeal
              onTimeChange={onTimeChange}
              unit={timeUnit}
              currentTime={timeLastMeal}
              restoreScrollPosition={restoreScrollPosition}
            />
          )}
        </div>
        {step === 0 && (
          <p className={s.privacy}>
            <img src={security} alt='lock' />
            <br />
            We need your data to provide fasting <br />
            recommendations. We never share it.
          </p>
        )}
      </div>
    </>
  );
};

export default Quiz;
