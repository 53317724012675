/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import s from './Prices.module.scss';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import visa from '../../img/paywall/visa.svg';
import mastercard from '../../img/paywall/mastercard.svg';
import mastercard2 from '../../img/paywall/mastercard2.svg';
import jcb from '../../img/paywall/jcb.svg';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY || 'stripe_key'
);

const Prices = () => {
  const navigate = useNavigate();
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [pricesFetchData, setPricesFetchData] = useState(null);
  console.log('pricesFetchData:', pricesFetchData);
  const [selectedOption, setSelectedOption] = useState(0);
  const [clientSecretValue, setClientSecretValue] = useState('');
  console.log('clientSecretValue:', clientSecretValue);
  const jwt = localStorage.getItem('jwt');
  console.log('jwt:', jwt);

  const handleSkip = async () => {
    localStorage.setItem('already_paid', true);
    navigate('/thanks');
  };

  const options = {
    clientSecret: clientSecretValue,
  };

  const periodHandler = index => {
    const fetchedPeriodInterval = pricesFetchData[index].duration.intervalCount;
    const fetchedPeriod = pricesFetchData[index].duration.interval;
    let daysCount = 7;

    if (fetchedPeriod === 'month') {
      daysCount = daysCount * 4.3 * fetchedPeriodInterval;
    }

    console.log('daysCount:', daysCount);
    return (pricesFetchData[index].main.amount / daysCount / 100).toFixed(2);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_LINK}/v1/product?mode=${process.env.REACT_APP_STRIPE_MODE}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        const pricesData = response.data;
        const mostPopularIndex = pricesData.findIndex(
          price => price.id === pricesData[1].id
        );
        setPricesFetchData(pricesData);
        setSelectedOption(mostPopularIndex);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleOptionChange = id => {
    const selectedIndex = pricesFetchData.findIndex(price => price.id === id);
    setSelectedOption(selectedIndex);
  };

  const buyButtonHandler = async () => {
    if (pricesFetchData) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}/v1/checkout/subscription`,
          {
            productId: pricesFetchData[selectedOption].id,
            mode: `${process.env.REACT_APP_STRIPE_MODE}`,
          },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
              'Content-Type': 'application/json',
            },
          }
        );

        setClientSecretValue(response.data.clientSecret);
        setShowCheckoutForm(true);
      } catch (error) {
        console.error('Error creating payment intent:', error);

        if (error.response && error.response.data.status === 'already_paid') {
          handleSkip();
        }
      }
    }
  };

  const closeCheckoutForm = () => {
    setShowCheckoutForm(false);
  };

  const discountHandler = index => {
    const originalAmount = pricesFetchData[index].main.amount;
    const increasedAmount = (originalAmount * 1.25) / 100;
    const roundedAmount = Math.ceil(increasedAmount);
    const formattedAmount = (roundedAmount - 0.01).toFixed(2);
    return formattedAmount;
  };

  const mostPopularBlock = () => (
    <div className={s.top_part}>
      <div className={s.top_part_text}>Most Popular</div>
    </div>
  );

  return (
    <>
      <div className={s.prices}>
        <ul>
          {pricesFetchData &&
            pricesFetchData.map((price, index) => (
              <li
                key={price.id}
                onClick={() => handleOptionChange(price.id)}
                className={index === selectedOption ? s.selected : ''}
              >
                <div className={s.main_part}>
                  {price.id === pricesFetchData[1].id && mostPopularBlock()}
                  <div className={s.bottom_part}>
                    <div className={s.left_part}>
                      <input
                        type='radio'
                        checked={selectedOption === index}
                        onChange={() => {}}
                      />
                      <div className={s.period_block}>
                        <div className={s.period}>{price.name}</div>
                        <div>
                          <span className={s.discount}>
                            ${discountHandler(index)}
                          </span>
                          <span className={s.price}>
                            {price.main.formattedPrice}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={s.per_block}>
                      <div className={s.perPrice}>${periodHandler(index)}</div>
                      <div className={s.perText}>per day</div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
        <button
          className={s.buyButton}
          onClick={() => buyButtonHandler()}
          disabled={showCheckoutForm}
        >
          get my plan
        </button>

        <div className={s.card_icons}>
          <img className={s.card_icon} src={visa} alt='visa' />
          <img className={s.card_icon} src={mastercard} alt='mastercard' />
          <img className={s.card_icon} src={mastercard2} alt='mastercard' />
          <img className={s.card_icon} src={jcb} alt='jcb' />
        </div>
        <div className={s.termsDescription}>
          Please be informed that your subscription is subject to auto-renewal
          unless you cancel it 24 hours before the auto-renewal date.
        </div>
        <div className={s.termsDescription}>
          By placing an order, you agree to Fasting’s{' '}
          <a
            href='https://fasting.beauty/termsofuse/'
            target='_blank'
            rel='noreferrer'
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            href='https://fasting.beauty/privacy/'
            target='_blank'
            rel='noreferrer'
          >
            Privacy Policy
          </a>
          .
        </div>
      </div>
      {showCheckoutForm && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            onClose={closeCheckoutForm}
            clientSecret={clientSecretValue}
            jwt={jwt}
          />
        </Elements>
      )}
    </>
  );
};

export default Prices;
