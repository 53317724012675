import React, { useEffect, useRef, useState } from 'react';
import s from './TimeFirstMeal.module.scss';
import cn from 'classnames';

const TimeFirstMeal = props => {
  const { onTimeChange, restoreScrollPosition } = props;
  const [time, setTime] = useState('');
  const [unit, setUnit] = useState('am');
  const timeInputRef = useRef(null);

  useEffect(() => {
    if (timeInputRef.current) {
      timeInputRef.current.focus();
    }
  }, [unit]);

  const handleTimeChange = event => {
    let newTime = event.target.value;
    newTime = newTime.replace(/[^0-9.,]/g, '');
    newTime = newTime.replace(/^0+/, '');

    const validFormat = /^[0-9]*[.,]?[0-9]*$/;

    if (validFormat.test(newTime) && newTime <= 12) {
      setTime(newTime);
      onTimeChange(newTime, unit);
    }
  };

  useEffect(() => {
    onTimeChange(time, unit);
  }, [time, unit, onTimeChange]);

  const handleUnitChange = newUnit => {
    setUnit(newUnit);
    setTime('');
    onTimeChange('', newUnit);
  };

  return (
    <div className={s.timeFirstMeal}>
      <div className={s.timeInput}>
        <input
          ref={timeInputRef}
          type='text'
          inputMode='decimal'
          pattern='[0-9]*'
          value={time}
          onChange={handleTimeChange}
          onBlur={restoreScrollPosition}
        />
        {unit}
      </div>
      <div className={s.units}>
        <div
          className={cn(s.unit, unit === 'pm' && s.unit_disabled)}
          onClick={() => handleUnitChange('am')}
        >
          am
        </div>
        <div
          className={cn(s.unit, unit === 'am' && s.unit_disabled)}
          onClick={() => handleUnitChange('pm')}
        >
          pm
        </div>
      </div>
    </div>
  );
};

export default TimeFirstMeal;
