import FirstScreen from './pages/FirstScreen/FirstScreen';
import Quiz from './pages/Quiz/Quiz';
import Login from './pages/Login/Login';
import PreLoad from './pages/PreLoad/PreLoad';
import ResultPage from './pages/ResultPage/ResultPage';
import './index.scss';
import { Routes, Route } from 'react-router-dom';
import ThanksPage from './pages/ThanksPage/ThanksPage';
import { AnswersProvider } from './helpers/AnswersContext';
import Paywall from './pages/Paywall/Paywall';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import ReactPixel from "react-facebook-pixel";

const App = () => {
  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {track_pageview: true});
    mixpanel.track('web_open');

    ReactPixel.init('1513901175835201', {}, {});
    ReactPixel.pageView();
    ReactPixel.track('InitiateCheckout');
  }, []);

  return (
    <div className='App'>
      <AnswersProvider>
        <Routes>
          <Route path='/' element={<FirstScreen />} />
          <Route path='/quiz' element={<Quiz />} />
          <Route path='/preload' element={<PreLoad />} />
          <Route path='/login' element={<Login />} />
          <Route path='/result' element={<ResultPage />} />
          <Route path='/paywall' element={<Paywall />} />
          <Route path='/thanks' element={<ThanksPage />} />
        </Routes>
      </AnswersProvider>
    </div>
  );
};

export default App;
