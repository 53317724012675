import s from './BeforeAfter.module.scss';
import before_female from '../../img/resultPage/before_female.jpg';
import after_female from '../../img/resultPage/after_female.jpg';
import before_male from '../../img/resultPage/before_male.jpg';
import after_male from '../../img/resultPage/after_male.jpg';
import arrows_right from '../../img/resultPage/arrows_right.png';
import mark from '../../img/resultPage/mark.png';
import cn from 'classnames';
import { useAnswers } from '../../helpers/AnswersContext';

const BeforeAfter = () => {
  const { answers } = useAnswers();
  const isMaleGender = answers.gender === 'male';
  const renderImageBefore = isMaleGender ? before_male : before_female;
  const renderImageAfter = isMaleGender ? after_male : after_female;

  return (
    <section
      style={{
        background: 'linear-gradient(to bottom, #EFEFFF 40%, #FFF, 50%, #FFF)',
      }}
    >
      <div className={s.beforeAfter}>
        <div className={s.block_row}>
          <div className={s.block}>
            <img className={s.block_img} src={renderImageBefore} alt='heart' />
            <div className={s.block_label}>Before</div>
          </div>
          <img src={arrows_right} className={s.arrows} alt='arrows right'></img>
          <div className={cn(s.block, s.after)}>
            <img className={s.block_img} src={renderImageAfter} alt='heart' />
            <div className={s.block_label}>After</div>
          </div>
        </div>
        <div className={s.block_text}>
          <div className={s.beforeAfter_title}>Your personal plan is ready</div>
          <div className={s.beforeAfter_title_add}>
            Here's what's included for you:
          </div>
          <div className={s.beforeAfter_description}>
            <img src={mark} className={s.mark} alt='mark' />
            <span>Improve health and lose weight</span>
          </div>
          <div className={s.beforeAfter_description}>
            <img src={mark} className={s.mark} alt='mark' />
            <span>Long-lasting results</span>
          </div>
          <div className={s.beforeAfter_description}>
            <img src={mark} className={s.mark} alt='mark' />
            <span>Tailored for beginners</span>
          </div>
          <div className={s.beforeAfter_description}>
            <img src={mark} className={s.mark} alt='mark' />
            <span>Adaptive to any lifestyle</span>
          </div>
        </div>
        <div className={s.beforeAfter_footer}></div>
        <div className={s.beforeAfter_footer_cut}></div>
        <div className={s.beforeAfter_footer_bg}></div>
      </div>
    </section>
  );
};

export default BeforeAfter;
