import React, { useEffect, useRef, useState } from 'react';
import s from './Height.module.scss';
import cn from 'classnames';

const Height = props => {
  const { onInputChange, restoreScrollPosition } = props;
  const [feet, setFeet] = useState('');
  const [inches, setInches] = useState('');
  const [unit, setUnit] = useState('inch');
  const feetInputRef = useRef(null);
  const inchesInputRef = useRef(null);
  const cmInputRef = useRef(null);

  useEffect(() => {
    if (unit === 'inch') {
      if (feetInputRef.current) {
        feetInputRef.current.focus();
      }
    } else {
      if (cmInputRef.current) {
        cmInputRef.current.focus();
      }
    }
  }, [unit]);

  const handleFeetChange = event => {
    let newFeet = event.target.value;
    newFeet = newFeet.replace(/[^0-9.,]/g, '');
    newFeet = newFeet.replace(/^0+/, '');

    const validFormat = /^[0-9]*[.,]?[0-9]*$/;

    if (validFormat.test(newFeet) && newFeet <= 999) {
      setFeet(newFeet);
      onInputChange(`${newFeet}`, unit);
    }
  };

  const handleInchesChange = event => {
    let newInches = event.target.value;
    newInches = newInches.replace(/[^0-9.,]/g, '');
    newInches = newInches.replace(/^0+/, '');

    const validFormat = /^[0-9]*[.,]?[0-9]*$/;

    if (validFormat.test(newInches) && newInches <= 11) {
      setInches(newInches);
      onInputChange(`${newInches}`, unit);
    }
  };

  useEffect(() => {
    onInputChange(`${feet}`, unit);
  }, [feet, inches, unit, onInputChange]);

  const handleUnitChange = newUnit => {
    setUnit(newUnit);
    setFeet('');
    setInches('');
    onInputChange('', newUnit);
  };

  return (
    <div className={s.height}>
      {unit === 'cm' ? (
        <div className={s.heightInput}>
          <input
            ref={cmInputRef}
            type='text'
            inputMode='decimal'
            pattern='[0-9.,]*'
            value={feet}
            onBlur={restoreScrollPosition}
            onChange={handleFeetChange}
          />
          {unit}
        </div>
      ) : (
        <div className={s.doubleInput}>
          <div className={s.heightInput}>
            <input
              ref={feetInputRef}
              type='text'
              inputMode='decimal'
              pattern='[0-9.,]*'
              value={feet}
              onBlur={restoreScrollPosition}
              onChange={handleFeetChange}
            />
            ft
          </div>
          <div className={s.heightInput}>
            <input
              ref={inchesInputRef}
              type='text'
              inputMode='decimal'
              pattern='[0-9.,]*'
              value={inches}
              onBlur={restoreScrollPosition}
              onChange={handleInchesChange}
            />
            in
          </div>
        </div>
      )}

      <div className={s.units}>
        <div
          className={cn(s.unit, unit === 'cm' && s.unit_disabled)}
          onClick={() => handleUnitChange('inch')}
        >
          Inch
        </div>
        <div
          className={cn(s.unit, unit === 'inch' && s.unit_disabled)}
          onClick={() => handleUnitChange('cm')}
        >
          Cm
        </div>
      </div>
    </div>
  );
};

export default Height;
