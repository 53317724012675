import React from 'react';
import style from './ProgressBar.module.scss';
import progress_arrowLeft from '../../img/progress_arrowLeft.png';
import mainLogo from '../../img/logo.png';

const ProgressBar = ({ percentage, stepBack }) => {
  return (
    <div className={style.progress_container}>
      <button className={style.progress__arrow} onClick={stepBack}>
        <img src={progress_arrowLeft} alt='arrow left' />
      </button>
      <div className={style.progress}>
        <div
          style={{ width: `${percentage}%` }}
          className={style.progress__inner}
        ></div>
      </div>
      <div className={style.progress__logo}>
        <img src={mainLogo} alt='logo' />
      </div>
    </div>
  );
};

export default ProgressBar;
