export const testimonialsData = [
  {
    author: '- Lily',
    stars: [1, 1, 1, 1, 1],
    title: 'Great for beginners',
    text: 'This is a simple and easy app to use and navigate. I like the interactions I have with the app. I recommend it!',
  },
  {
    author: '- Frankie',
    stars: [1, 1, 1, 1, 1],
    title: 'Love it! Helpful and Encouraging!',
    text: 'I’ve been fasting for a few months now, and I have very rarely broken the fast. Can’t recommend it enough ❤️',
  },
  {
    author: '- Janny',
    stars: [1, 1, 1, 1, 1],
    title: 'I don’t even know what to say',
    text: 'I’ve tried pretty much every diet and program Under the sun. I went into this fairly skeptical and within the first week I’ve lost 13 pounds.',
  },
  {
    author: '- Madi',
    stars: [1, 1, 1, 1, 1],
    title: 'Great, easy to use app',
    text: 'I like to go as long as I can without having to follow any rules. I’m ready to fast on with this way to use app!',
  },
];
