import s from './Paywall.module.scss';
import Testimonials from '../../components/Testimonials/Testimonials';
import Prices from '../../components/Prices/Prices';
import { useAnswers } from '../../helpers/AnswersContext';
import BeforeAfterV2 from '../../components/BeforeAfterV2/BeforeAfterV2';
import Chart from '../../components/Chart/Chart';
import logo from '../../img/logo.png';
import MainGoalV2 from '../../components/MainGoalV2/MainGoalV2';
import MarkedList from '../../components/MarkedList/MarkedList';
import MoneyBack from '../../components/MoneyBack/MoneyBack';
import SliderCards from '../../components/SliderCards/SliderCards';

const Paywall = () => {
  const { answers } = useAnswers();
  const mainGoalAnswer = answers.mainGoal;
  const currentWeightAnswer = answers.currentWeight;
  const goalWeightAnswer = answers.goalWeight;
  const unitAnswer = answers.currentWeight?.scale;
  const whenResultsAnswer = answers.whenResults;

  let unitSystem = unitAnswer === 'imperial' ? 'Lbs' : 'Kg';

  const formatDate = date => {
    const options = { day: 'numeric', month: 'short' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  const getNextDate = (currentDate, daysToAdd) => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + daysToAdd);
    return newDate;
  };

  let weeksWhenGoal = whenResultsAnswer?.variants;
  currentWeightAnswer === goalWeightAnswer && (weeksWhenGoal = 0);

  const today = new Date();
  const weakToGoalCalculated = getNextDate(today, weeksWhenGoal * 7);

  return (
    <div className={s.paywall}>
      <div className={s.header}>
        <img className={s.header_logo} src={logo} alt="logo"/>
        <div>Your personal fasting plan is ready!</div>
      </div>
      <BeforeAfterV2/>
      <div className={s.bg_gray}>
        <MainGoalV2 mainGoal={mainGoalAnswer} goalWeight={goalWeightAnswer}/>
        <Prices/>
      </div>
      <Testimonials/>
      <SliderCards/>
      <MarkedList/>
      <div className={s.chartTitle}>
        <div className={s.chartTitle_title}>You can reach your goal of</div>
        <div className={s.chartTitle_stats}>
          <span className={s.chartTitle_weight}>
            {goalWeightAnswer?.value || '50'} {unitSystem}{' '}
            <span style={{ color: '#333' }}>by</span>
          </span>{' '}
          <span></span>
          {formatDate(weakToGoalCalculated)}
        </div>
      </div>
      <Chart
        currentWeight={currentWeightAnswer?.value}
        goalWeight={goalWeightAnswer?.value}
        units={unitAnswer}
        whenResults={whenResultsAnswer}
      />
      <div className={s.bg_gray}>
        <MainGoalV2 mainGoal={mainGoalAnswer} goalWeight={goalWeightAnswer}/>
        <Prices/>
      </div>
      <MoneyBack/>
    </div>
  );
};

export default Paywall;
