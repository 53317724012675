import React, { useEffect, useRef, useState } from 'react';
import s from './CurrentWeight.module.scss';
import cn from 'classnames';

const CurrentWeight = props => {
  const { onInputChange, restoreScrollPosition } = props;
  const [weight, setWeight] = useState('');
  const [unit, setUnit] = useState('Lbs');
  const weightInputRef = useRef(null);

  useEffect(() => {
    if (weightInputRef.current) {
      weightInputRef.current.focus();
    }
  }, [unit]);

  const handleWeightChange = event => {
    let newWeight = event.target.value;
    newWeight = newWeight.replace(/[^0-9.,]/g, '');
    newWeight = newWeight.replace(/^0+/, '');

    const validFormat = /^[0-9]*[.,]?[0-9]*$/;

    if (validFormat.test(newWeight) && newWeight <= 999) {
      setWeight(newWeight);
      onInputChange(newWeight, unit);
    }
  };

  useEffect(() => {
    onInputChange(weight, unit);
  }, [weight, unit, onInputChange]);

  const handleUnitChange = newUnit => {
    setUnit(newUnit);
    setWeight('');
    onInputChange('', newUnit);
  };

  return (
    <div className={s.currentWeight}>
      <div className={s.weightInput}>
        <input
          ref={weightInputRef}
          type='text'
          inputMode='decimal'
          pattern='[0-9.,]*'
          value={weight}
          onBlur={restoreScrollPosition}
          onChange={handleWeightChange}
        />
        {unit}
      </div>
      <div className={s.units}>
        <div
          className={cn(s.unit, unit === 'Kg' && s.unit_disabled)}
          onClick={() => handleUnitChange('Lbs')}
        >
          Lbs
        </div>
        <div
          className={cn(s.unit, unit === 'Lbs' && s.unit_disabled)}
          onClick={() => handleUnitChange('Kg')}
        >
          Kg
        </div>
      </div>
    </div>
  );
};

export default CurrentWeight;
