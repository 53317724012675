import s from './MarkedList.module.scss';
import mark from '../../img/paywall/mark.png';

const MarkedList = () => {
  return (
    <div className={s.bg_gray}>
      <section>
        <div className={s.markedList}>
          <div className={s.block_text}>
            <div className={s.markedList_title_add}>What you get:</div>
            <div className={s.markedList_description}>
              <img src={mark} className={s.mark} alt='mark' />
              <span>Lose weight with no diets</span>
            </div>
            <div className={s.markedList_description}>
              <img src={mark} className={s.mark} alt='mark' />
              <span>Forget about crazy food restrictions</span>
            </div>
            <div className={s.markedList_description}>
              <img src={mark} className={s.mark} alt='mark' />
              <span>Improve heart and brain health</span>
            </div>
            <div className={s.markedList_description}>
              <img src={mark} className={s.mark} alt='mark' />
              <span>Improve mental health</span>
            </div>
            <div className={s.markedList_description}>
              <img src={mark} className={s.mark} alt='mark' />
              <span>Build healthier lifestyle</span>
            </div>
            <div className={s.markedList_description}>
              <img src={mark} className={s.mark} alt='mark' />
              <span>Improve life quality</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MarkedList;
