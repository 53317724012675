import React from 'react';
import s from './MainGoal.module.scss';
import reachDarts from '../../img/resultPage/reach_darts.png';
import mark from '../../img/resultPage/mark.png';

const MainGoal = props => {
  const {
    mainGoal = { variants: ['Lose weight'], icons: ['quest_icon_darts'] },
    specificDiet = { variants: ['No diet'], icons: ['quest_icon_nodiet'] },
    additionalGoal = {
      variants: ['Reduce stress', 'Improve your concentration', 'Sleep better'],
      icons: [
        'quest_icon_reducestress',
        'quest_icon_concentration',
        'quest_icon_sleepbetter',
      ],
    },
    weeksWhenGoal = 32,
  } = props;

  return (
    <>
      <div className={s.mainGoal}>
        <div className={s.mainGoal_text}>
          <div className={s.mainGoal_title}>Main goal</div>
          <div>{mainGoal.variants[0]}</div>
        </div>
        <img
          src={require(`../../img/questionIcons/${mainGoal.icons[0]}.png`)}
          className={s.mainGoal_icon}
          alt='mainGoal'
        />
      </div>

      <div className={s.reachGoal}>
        <div className={s.reachGoal_text}>
          <div className={s.reachGoal_title}>Reach your goal in</div>
          <div className={s.reachGoal_subtitle}>{weeksWhenGoal} weeks</div>
          <div className={s.reachGoal_description}>
            Visible results in just 3 weeks
          </div>
        </div>
        <img src={reachDarts} className={s.reachGoal_icon} alt='reachGoal' />
      </div>

      <div className={s.addGoal}>
        <div className={s.addGoal_title}>Additional goal</div>
        {additionalGoal.variants.map((variant, index) => (
          <div className={s.addGoal_option} key={index}>
            <div className={s.addGoal_text}>
              <img
                src={require(`../../img/questionIcons/${additionalGoal.icons[index]}.png`)}
                className={s.addGoal_icon}
                alt='goal'
              />
              <div>{variant}</div>
            </div>
            <img src={mark} className={s.addGoal_mark} alt='mark' />
          </div>
        ))}
      </div>

      <div className={s.dietBlock}>
        <div className={s.dietBlock_text}>
          <div className={s.dietBlock_title}>Special diet</div>
          <div>{specificDiet.variants[0]}</div>
        </div>
        <img
          src={require(`../../img/questionIcons/${specificDiet.icons[0]}.png`)}
          className={s.dietBlock_icon}
          alt='diet'
        />
      </div>
    </>
  );
};

export default MainGoal;
