import s from './ThanksPage.module.scss';
// import axios from 'axios';
import logo from '../../img/logo.png';
import {useEffect} from "react";
import ReactPixel from "react-facebook-pixel";

const ThanksPage = () => {
  // const jwt = localStorage.getItem('jwt');
  const already_paid = localStorage.getItem('already_paid');
  localStorage.removeItem('already_paid');
  console.log('is already_paid:', already_paid);

  useEffect(() => {
    ReactPixel.init('1513901175835201', {}, {});
    ReactPixel.track('Purchase', {
      content_type: 'subscription',
      content_ids: ['subscription'],
      value: 4.99, currency: 'USD'
    });
  }, []);

  // const deleteHandler = async () => {
  //   try {
  //     await axios.delete(`${process.env.REACT_APP_API_LINK}/v1/subscription`, {
  //       data: {},
  //       headers: {
  //         Authorization: `Bearer ${jwt}`,
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //     console.log('Подписка успешно удалена');
  //   } catch (error) {
  //     console.error('Ошибка при удалении подписки:', error);
  //   }
  // };

  return (
    <div className={s.thanksPage}>
      <div>
        <div className={s.navbar}>
          <div>
            <img className={s.logo} src={logo} alt='Fasting'></img>
          </div>
          <h2>
            <a href='/'>Fasting</a>
          </h2>
        </div>
        {!already_paid ? (
          <>
            <div className={s.title}>Thanks for being our customer!</div>
            <p className={s.title_description}>
              Go to the app, Log in the same way,
              <br /> Take your Personal plan and follow it!
            </p>
            <a
              href='https://apps.apple.com/app/id1638123672'
              className={s.tnx_button}
            >
              Go to the app
            </a>
          </>
        ) : (
          <>
            <p className={s.title_description_already_paid}>
              Go to the app, Log in the same way,
              <br /> Take your Personal plan and follow it!
            </p>
            <a
              href='https://apps.apple.com/app/id1638123672'
              className={s.tnx_button}
            >
              go to the app
            </a>
          </>
        )}
        <br />
        {/* <button className={s.tnx_button} onClick={() => deleteHandler()}>
          Del sub
        </button> */}
      </div>
    </div>
  );
};

export default ThanksPage;
