import React, { useRef, useState } from 'react';
import s from './PreLoad.module.scss';
import cn from 'classnames';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import spinner from '../../img/preloadPage/spinner.png';
import slide_1 from '../../img/preloadPage/preload_1.png';
import slide_2 from '../../img/preloadPage/preload_2.png';
import slide_3 from '../../img/preloadPage/preload_3.png';
import slide_4 from '../../img/preloadPage/preload_4.png';
import slide_5 from '../../img/preloadPage/preload_5.png';
import { useNavigate } from 'react-router-dom';

SwiperCore.use([Navigation, Autoplay]);

const slides = [
  { img: slide_1, description: 'Get a plan that suits for your lifestyle' },
  { img: slide_2, description: 'Stick to your schedule and skip one meal' },
  { img: slide_3, description: 'Monitor your body condition during fasting' },
  { img: slide_4, description: 'Lose weight when the Fat Burning kicks in' },
  { img: slide_5, description: 'Learn how to fast healthily and effectively' },
];

const PreLoad = () => {
  const [isSliderFinished, setIsSliderFinished] = useState(false);
  const [isAutoplayStopped, setIsAutoplayStopped] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const handleModalButtonClick = () => {
    setModalShow(false);
    swiperRef.current.autoplay.start();
  };

  const handleSwiperInit = swiper => {
    swiper.autoplay.start();
  };

  const swiperParams = {
    slidesPerView: 1,
    centeredSlides: true,
    initialSlide: 0,
    speed: 1500,
    allowTouchMove: false,
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
    },
    onSlideChange: swiper => {
      const isThirdSlide = swiper.realIndex === 2;
      const isLastSlide = swiper.realIndex === 4;

      if (isThirdSlide && !isAutoplayStopped) {
        setIsAutoplayStopped(true);
        swiper.autoplay.stop();
        setModalShow(true);
      }

      if (isLastSlide) {
        swiper.autoplay.stop();
        console.log('DONE');

        setTimeout(() => {
          setIsSliderFinished(true);
        }, 2000);

        setTimeout(() => {
          navigate('/result');
        }, 3000);
      }
    },
  };

  return (
    <div className={cn(s.preLoad, isSliderFinished && s.hide)}>
      <div className={s.loader_container}>
        <div className={s.loadTitle}>
          <div>Creating your plan...</div>
          <img src={spinner} className={s.spinner} alt='loading' />
        </div>
        <div className={s.testimonials_container}>
          <div className={s.testimonials_swiper}>
            <Swiper
              {...swiperParams}
              onSwiper={swiper => {
                handleSwiperInit(swiper);
                swiperRef.current = swiper;
              }}
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div key={index} className={cn(s.slidesLoad)}>
                    <div className={cn(s.progressContainer)}>
                      <img
                        src={slide.img}
                        alt={`slide_${index}`}
                        className={cn(s.slide)}
                      />
                    </div>
                    <div className={cn(s.description)}>{slide.description}</div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      {modalShow && (
        <div className={s.overlay}>
          <div className={s.modal}>
            <div className={s.modal_question}>
              Do you agree with the following statement?
            </div>
            <div className={s.modal_description}>
              “I need some outside motivation. When I’m feeling overwhelmed, it
              can be easy to give up”.
            </div>
            <div className={s.modal_buttons}>
              <div className={s.modal_button} onClick={handleModalButtonClick}>
                Yes
              </div>
              <div className={s.modal_button} onClick={handleModalButtonClick}>
                No
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreLoad;
