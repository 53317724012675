import React from 'react';
import s from './Title.module.scss';

const Title = (props) => {
  const {
    fullText,
    textColor = '#002459',
    markedColor = '#34C759',
    markedText = [],
  } = props;
  const markedElements = [];

  let remainingText = fullText;

  markedText.forEach((text, index) => {
    const parts = remainingText.split(text);

    if (parts.length > 1) {
      markedElements.push(
        parts[0],
        <span key={index} className={s.text_marked} style={{ color: markedColor }}>
          {text}
        </span>
      );
      remainingText = parts.slice(1).join(text);
    }
  });

  markedElements.push(remainingText);

  return <div className={s.title} style={{ color: textColor }}>{markedElements}</div>;
};

export default Title;
