import React from 'react';
import s from './FastingPlan.module.scss';

const FastingPlan = props => {
  const {
    timeFirstMeal = {
      value: '12',
      unit: 'am',
    },
    // timeLastMeal = {
    //   timeFirstMeal: '12',
    //   unit: 'pm',
    // },
    whenResults = {
      variants: ['12 weeks'],
      variantsDescription: ['0,5 kg / week'],
      variantsValues: [0.5],
      variantsDifficulty: ['Easy'],
    },
  } = props;

  const hoursFasting = () => {
    switch (whenResults.variants[0]) {
      case '12 weeks':
        return 12;
      case '10 weeks':
        return 14;
      case '8 weeks':
        return 16;
      case '7 weeks':
        return 16;
      case '6 weeks':
        return 18;
      case '5 weeks':
        return 18;
      case '4 weeks':
        return 18;
      default:
        return 12;
    }
  };

  const hoursEating = () => {
    switch (whenResults.variants[0]) {
      case '12 weeks':
        return 12;
      case '10 weeks':
        return 10;
      case '8 weeks':
        return 8;
      case '7 weeks':
        return 8;
      case '6 weeks':
        return 6;
      case '5 weeks':
        return 6;
      case '4 weeks':
        return 6;
      default:
        return 12;
    }
  };

  const difficulty = whenResults.variantsDifficulty[0];

  const convertTo12HourFormat = hour => {
    if (hour > 12) {
      return hour - 12;
    } else if (hour === 0) {
      return 12;
    } else {
      return hour;
    }
  };

  const eatingWindow = convertTo12HourFormat(
    Number(timeFirstMeal.value) + hoursEating()
  );

  return (
    <>
      <div className={s.fastingPlan}>
        <div className={s.fastingPlan_title}>Initial Fasting plan</div>
        <div>
          {hoursFasting()} h fast with a {hoursEating()} h eating window
        </div>
      </div>

      <div className={s.fastingPlan}>
        <div className={s.fastingPlan_title}>Difficulty</div>
        <div>{difficulty}</div>
      </div>

      <div className={s.fastingDouble}>
        <div className={s.fastingPlan}>
          <div className={s.fastingPlan_title}>
            Fasting start <br /> time
          </div>
          <div>
            At {timeFirstMeal.value}:00{' '}
            <span className={s.timeUnit}>{timeFirstMeal.unit}</span>
          </div>
        </div>

        <div className={s.fastingPlan}>
          <div className={s.fastingPlan_title}>
            Eating <br /> window
          </div>
          <div>
            {timeFirstMeal.value}:00 AM to <br /> {eatingWindow}:00 PM
          </div>
        </div>
      </div>
    </>
  );
};

export default FastingPlan;
