import s from './FirstScreen.module.scss';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import logo from '../../img/firstScreen/logo.png';
import security from '../../img/firstScreen/security.png';
import female from '../../img/firstScreen/female.png';
import male from '../../img/firstScreen/male.png';
import non from '../../img/firstScreen/non.png';
import { useAnswers } from '../../helpers/AnswersContext';
import { useEffect, useState } from 'react';

const FirstScreen = () => {
  const navigate = useNavigate();
  const { saveAnswers } = useAnswers();
  const [selectedGender, setSelectedGender] = useState(null);

  useEffect(() => {
    const handleSkip = async () => {
      if (selectedGender !== null) {
        setTimeout(() => {
          const newAnswers = {
            ...saveAnswers(),
            gender: selectedGender === 'male' ? 'male' : 'female',
          };
          saveAnswers(newAnswers);
          navigate('/quiz');
        }, 300);
      }
    };

    handleSkip();
  }, [navigate, saveAnswers, selectedGender]);

  return (
    <div className={s.firstScreen}>
      <div className={s.bg_image2}></div>
      <div style={{ marginTop: '-130px' }}>
        <img src={logo} className={s.logo} alt='logo' />
        <div className={cn(s.title, s.marked)}>Intermittent Fasting</div>
      </div>
      <div>
        <div className={s.title}>
          Take the first step in <br /> changing your life!
        </div>
        <p className={s.title_description}>
          Complete a 5-minute quiz to get a personalized plan
        </p>
      </div>
      <div>
        <p className={s.title_additional}>Choose your gender to start:</p>
        <ul>
          <li onClick={() => setSelectedGender('female')}>
            <div className={s.quest_textAndIcon}>
              <img className={s.quest_icon} src={female} alt='icon' />
              <div className={s.quest_textWithDescription}>
                <div>Female</div>
              </div>
            </div>
            <div className={s.radio}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='8'
                height='16'
                viewBox='0 0 8 16'
                fill='none'
              >
                <path
                  d='M0.0520926 1.00509C0.0523262 1.27022 0.157835 1.5244 0.345425 1.71176L5.46009 6.82643C5.6149 6.9812 5.73771 7.16495 5.82149 7.36719C5.90528 7.56942 5.9484 7.78619 5.9484 8.00509C5.9484 8.224 5.90528 8.44076 5.82149 8.643C5.73771 8.84524 5.6149 9.02899 5.46009 9.18376L0.352092 14.2918C0.169934 14.4804 0.0691395 14.733 0.0714178 14.9952C0.0736961 15.2574 0.178865 15.5082 0.364274 15.6936C0.549682 15.879 0.800494 15.9842 1.06269 15.9864C1.32489 15.9887 1.57749 15.8879 1.76609 15.7058L6.87409 10.6018C7.56092 9.91358 7.94666 8.98103 7.94666 8.00876C7.94666 7.03649 7.56092 6.10393 6.87409 5.41576L1.75943 0.297759C1.61957 0.157815 1.44135 0.0625014 1.24731 0.0238786C1.05327 -0.0147441 0.852129 0.00506 0.669348 0.080785C0.486566 0.15651 0.330354 0.284752 0.220479 0.449285C0.110603 0.613817 0.0520029 0.807245 0.0520926 1.00509Z'
                  fill='#B2B1FF'
                />
              </svg>
            </div>
          </li>
          <li onClick={() => setSelectedGender('male')}>
            <div className={s.quest_textAndIcon}>
              <img className={s.quest_icon} src={male} alt='icon' />
              <div className={s.quest_textWithDescription}>
                <div>Male</div>
              </div>
            </div>
            <div className={s.radio}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='8'
                height='16'
                viewBox='0 0 8 16'
                fill='none'
              >
                <path
                  d='M0.0520926 1.00509C0.0523262 1.27022 0.157835 1.5244 0.345425 1.71176L5.46009 6.82643C5.6149 6.9812 5.73771 7.16495 5.82149 7.36719C5.90528 7.56942 5.9484 7.78619 5.9484 8.00509C5.9484 8.224 5.90528 8.44076 5.82149 8.643C5.73771 8.84524 5.6149 9.02899 5.46009 9.18376L0.352092 14.2918C0.169934 14.4804 0.0691395 14.733 0.0714178 14.9952C0.0736961 15.2574 0.178865 15.5082 0.364274 15.6936C0.549682 15.879 0.800494 15.9842 1.06269 15.9864C1.32489 15.9887 1.57749 15.8879 1.76609 15.7058L6.87409 10.6018C7.56092 9.91358 7.94666 8.98103 7.94666 8.00876C7.94666 7.03649 7.56092 6.10393 6.87409 5.41576L1.75943 0.297759C1.61957 0.157815 1.44135 0.0625014 1.24731 0.0238786C1.05327 -0.0147441 0.852129 0.00506 0.669348 0.080785C0.486566 0.15651 0.330354 0.284752 0.220479 0.449285C0.110603 0.613817 0.0520029 0.807245 0.0520926 1.00509Z'
                  fill='#B2B1FF'
                />
              </svg>
            </div>
          </li>
          <li
            onClick={() => {
              setSelectedGender('non');
            }}
          >
            <div className={s.quest_textAndIcon}>
              <img className={s.quest_icon} src={non} alt='icon' />
              <div className={s.quest_textWithDescription}>
                <div>Non-binary</div>
              </div>
            </div>
            <div className={s.radio}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='8'
                height='16'
                viewBox='0 0 8 16'
                fill='none'
              >
                <path
                  d='M0.0520926 1.00509C0.0523262 1.27022 0.157835 1.5244 0.345425 1.71176L5.46009 6.82643C5.6149 6.9812 5.73771 7.16495 5.82149 7.36719C5.90528 7.56942 5.9484 7.78619 5.9484 8.00509C5.9484 8.224 5.90528 8.44076 5.82149 8.643C5.73771 8.84524 5.6149 9.02899 5.46009 9.18376L0.352092 14.2918C0.169934 14.4804 0.0691395 14.733 0.0714178 14.9952C0.0736961 15.2574 0.178865 15.5082 0.364274 15.6936C0.549682 15.879 0.800494 15.9842 1.06269 15.9864C1.32489 15.9887 1.57749 15.8879 1.76609 15.7058L6.87409 10.6018C7.56092 9.91358 7.94666 8.98103 7.94666 8.00876C7.94666 7.03649 7.56092 6.10393 6.87409 5.41576L1.75943 0.297759C1.61957 0.157815 1.44135 0.0625014 1.24731 0.0238786C1.05327 -0.0147441 0.852129 0.00506 0.669348 0.080785C0.486566 0.15651 0.330354 0.284752 0.220479 0.449285C0.110603 0.613817 0.0520029 0.807245 0.0520926 1.00509Z'
                  fill='#B2B1FF'
                />
              </svg>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <div className={s.privacy}>
          <img src={security} alt='security' />
          <div>Your data is protected</div>
        </div>
        <div className={s.footer}>
          <a className={s.link} href='https://fasting.beauty/termsofuse/'>
            Terms of Service
          </a>
          <a className={s.link} href='http://fasting.beauty/privacy'>
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default FirstScreen;
