import s from './ResultPage.module.scss';
import Chart from '../../components/Chart/Chart';
import BeforeAfter from '../../components/BeforeAfter/BeforeAfter';
import { useNavigate } from 'react-router-dom';
import MainGoal from '../../components/MainGoal/MainGoal';
import FastingPlan from '../../components/FastingPlan/FastingPlan';
import { useAnswers } from '../../helpers/AnswersContext';

const ResultPage = () => {
  const { answers } = useAnswers();
  const navigate = useNavigate();

  //Weight
  const currentWeightAnswer = answers.currentWeight;
  const goalWeightAnswer = answers.goalWeight;

  //Height
  const heightAnswer = answers.height;

  //Goals
  const mainGoalAnswer = answers.mainGoal;
  const additionalGoalAnswer = answers.additionalGoal;
  const specificDietAnswer = answers.specificDiet;

  //Weeks
  const whenResultsAnswer = answers.whenResults;
  const weeksWhenGoal = whenResultsAnswer?.variants;

  //Fasting plan
  const timeFirstMealAnswer = answers.timeFirstMeal;
  console.log('timeFirstMealAnswer:', timeFirstMealAnswer);
  const timeLastMealAnswer = answers.timeLastMeal;

  const saveDataToLocalStorage = (answers, currentWeight, height) => {
    const dataToSave = JSON.stringify({
      metadata: answers,
      weight: currentWeight,
      height: height,
    });

    localStorage.setItem('userData', dataToSave);
  };

  return (
    <div className={s.resultPage}>
      <BeforeAfter />
      <MainGoal
        mainGoal={mainGoalAnswer}
        additionalGoal={additionalGoalAnswer}
        specificDiet={specificDietAnswer}
        weeksWhenGoal={weeksWhenGoal}
      />
      <Chart
        currentWeight={currentWeightAnswer?.value}
        goalWeight={goalWeightAnswer?.value}
        units={currentWeightAnswer?.scale}
        whenResults={whenResultsAnswer}
      />
      <FastingPlan
        whenResults={whenResultsAnswer}
        timeFirstMeal={timeFirstMealAnswer}
        timeLastMeal={timeLastMealAnswer}
      />
      <button
        className={s.btn}
        onClick={() => {
          saveDataToLocalStorage(answers, currentWeightAnswer, heightAnswer);
          navigate('/login');
        }}
      >
        CONTINUE
      </button>
    </div>
  );
};

export default ResultPage;
