import React from 'react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './CardsSwiper.css';
import s from './SliderCards.module.scss';

import card_1 from '../../img/paywall/card_1.jpg';
import card_2 from '../../img/paywall/card_2.jpg';
import card_3 from '../../img/paywall/card_3.jpg';
import card_4 from '../../img/paywall/card_4.jpg';
import card_5 from '../../img/paywall/card_5.jpg';
import card_6 from '../../img/paywall/card_6.jpg';
import card_7 from '../../img/paywall/card_7.jpg';
import card_8 from '../../img/paywall/card_8.jpg';

SwiperCore.use([Navigation, Autoplay]);

const SliderCards = () => {
  const swiperParams = {
    initialSlide: 0,
    slidesPerView: 2,
    // centeredSlides: true,
    // loop: true,
    spaceBetween: 10,
    autoplay: false,
  };

  const cards = [
    card_1,
    card_2,
    card_3,
    card_4,
    card_5,
    card_6,
    card_7,
    card_8,
  ];

  return (
    <>
      <div className={s.mainTitle}>
        Fasting app will guide you through your{' '}
        <span className={s.marked}>Intermittent Fasting Plan</span>
      </div>
      <div className='Cards-container'>
        <div className='Cards-swiper'>
          <Swiper {...swiperParams}>
            {cards.map((card, index) => (
              <SwiperSlide key={index}>
                <div className='card-swiper-slide-content'>
                  <img className={s.card} src={card} alt='card' />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SliderCards;
