import s from './MoneyBack.module.scss';
import money_back from '../../img/paywall/money_back.svg';

const MoneyBack = () => {
  return (
    <div className={s.moneyBack}>
      <div className={s.block_row}>
        <div className={s.block}>
          <img className={s.block_img} src={money_back} alt='money back' />
          <div>30-day money-back guarantee</div>
        </div>
      </div>
      <div className={s.description}>
        We are confident of our service quality and its results. So, if you are
        ready to reach your goals, it’s a risk-free offer. We guarantee you’ll
        see visible results or you’ll receive a full refund within 30 days after
        your purchase. You will need to demonstrate that you have followed the
        program.
      </div>
      <div className={s.footer}>
        <a href='https://fasting.beauty/termsofuse/'>Terms of Service</a>
        <a href='http://fasting.beauty/privacy'>Privacy Policy</a>
        <a href='mailto:support@airshow.dev'>Contact us</a>
      </div>
    </div>
  );
};

export default MoneyBack;
