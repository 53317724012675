import React from 'react';
import s from './Chart.module.scss';
import cn from 'classnames';
import chart_down from '../../img/resultPage/chart_down.png';
import chart_up from '../../img/resultPage/chart_up.png';
import chart_middle from '../../img/resultPage/chart_middle.png';

const Chart = props => {
  const {
    currentWeight = 89,
    goalWeight = 50,
    units = 'imperial',
    whenResults = {
      variants: [32],
      variantsDescription: [' / week'],
      variantsValues: [0.5],
    },
  } = props;

  console.log('units:', units);
  let unitSystem = units === 'imperial' ? 'Lbs' : 'Kg';

  const isWeightGain = Number(currentWeight) < Number(goalWeight);
  const isWeightLost = Number(currentWeight) > Number(goalWeight);
  const isWeightSave = Number(currentWeight) === Number(goalWeight);
  let weightSign = isWeightLost ? '-' : isWeightGain ? '+' : '';

  const currentWeightClass = isWeightGain
    ? cn(s.chartGraph_currentWeight, s.chartGraph_currentWeight_gain)
    : isWeightLost
    ? s.chartGraph_currentWeight
    : cn(s.chartGraph_currentWeight, s.chartGraph_currentWeight_save);

  const goalWeightClass = isWeightGain
    ? cn(s.chartGraph_goalWeight, s.chartGraph_goalWeight_gain)
    : isWeightLost
    ? s.chartGraph_goalWeight
    : cn(s.chartGraph_goalWeight, s.chartGraph_goalWeight_save);

  const renderChartVariant = () => {
    return (
      <img
        src={isWeightLost ? chart_down : isWeightGain ? chart_up : chart_middle}
        className={s.chartGraph_img}
        alt='chart'
      />
    );
  };

  const formatDate = date => {
    const options = { day: 'numeric', month: 'short' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  const getNextDate = (currentDate, daysToAdd) => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + daysToAdd);
    return newDate;
  };

  const weeksWhenGoal = whenResults?.variants;

  const today = new Date();
  const next30Days = getNextDate(today, 30);
  const next90Days = getNextDate(today, weeksWhenGoal * 7);

  return (
    <div className={s.chart}>
      <div className={s.chart_text}>
        <div className={s.chart_title}>Weight Progress</div>
        <div className={s.chart_title_weight}>
          {weightSign} {isWeightSave ? 0 : whenResults.variantsValues[0]}{' '}
          {unitSystem} per week
        </div>
      </div>
      <div className={s.chartGraph}>
        <div className={s.chartGraph_container}>
          <div className={currentWeightClass}>
            {currentWeight} {unitSystem}
          </div>
          <div className={goalWeightClass}>{Number(goalWeight).toFixed(1)}</div>
          {renderChartVariant()}
        </div>
        <div className={s.chartGraph_date}>
          <div>Today</div>
          <div>{formatDate(next30Days)}</div>
          <div>{formatDate(next90Days)}</div>
        </div>
      </div>
    </div>
  );
};

export default Chart;
