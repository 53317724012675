export const quizData = [
  {
    name: 'mainGoal',
    title: 'Choose your main goal',
    description: 'What do you want to achieve with us?',
    variants: [
      'Lose weight',
      'Get healthier',
      'Keep in shape',
      'Feel more energetic',
    ],
    icons: [
      'quest_icon_darts',
      'quest_icon_apple',
      'quest_icon_heart_dmb',
      'quest_icon_lightning',
    ],
  },
  {
    name: 'perfectWeightLoss',
    title: 'Perfect for weight loss',
    markedTitle: 'Perfect for weight loss',
    text: 'No calorie restrictions. You can eat whatever you want.',
    text2:
      'Natural cleansing. During fasting, your body will burn fats and eliminate toxins.',
    text3:
      'The scientifically proven method that improves your body and mind health',
    withImage: 'quest_img_perfect',
    maybeMale: true,
  },
  {
    name: 'ageGroup',
    title: 'Choose your age group',
    description: 'We can personalize your plan based on your age',
    variants: ['18-24', '25-34', '35-44', '45-54', '55+'],
  },
  {
    name: 'mealsPerDay',
    title: 'How many meals do you eat per day?',
    description: 'Help us generate the plan that works best for you',
    variants: [
      'Only one',
      'Usually two times',
      'Three times',
      'More than three',
    ],
    icons: [
      'quest_icon_num1',
      'quest_icon_num2',
      'quest_icon_num3',
      'quest_icon_num4',
    ],
  },
  {
    name: 'carbDense',
    title:
      'How often do you eat carb-dense foods like bread, dairy, pasta, etc?',
    description: 'No judgment: This will help us personalize your plan',
    variants: ['Very often', 'Sometimes', 'Rarely', 'Not at all'],
  },
  {
    name: 'specificDiet',
    title: 'Do you follow a specific diet?',
    description: 'We offer tips and motivation on your journey',
    variants: ['No diet', 'Pescatarian', 'Vegetarian', 'Vegan', 'Other'],
    variantsDescription: [
      'I have no preferences',
      'I eat seafood but no meat',
      'I don’t eat meat or seafood',
      'I don’t eat any animal products',
      'Any other preferences',
    ],
    icons: [
      'quest_icon_nodiet',
      'quest_icon_fish',
      'quest_icon_cheese',
      'quest_icon_broccoli',
      'quest_icon_other',
    ],
  },
  {
    name: 'LoseWeightByFasting',
    title: 'Lose weight by fasting, not dieting',
    markedTitle: 'Lose weight by fasting, not dieting',
    text: 'We believe in natural weight loss without restrictions to daily routine. Say goodbye to yo-yo effects, dangerous diets and constant calorie counting, as we will help you lose weight sustainably by teaching healthy habits that last a lifetime.',
    text2: 'Start a healthier and happier life!',
    withImage: 'quest_img_chart',
  },
  {
    name: 'height',
    title: 'What is your height?',
    description: 'Height is needed to determine a safe weight loss rate',
    height: true,
    nextButton: true,
  },
  {
    name: 'currentWeight',
    title: 'What is your current weight?',
    description: 'Weight is needed to calculate your BMI',
    currentWeight: true,
    nextButton: true,
  },
  {
    name: 'goalWeight',
    title: 'What is your goal weight?',
    description:
      'We generate your personal plan and its duration based on how much weight you want to lose',
    goalWeight: true,
    nextButton: true,
  },
  {
    name: 'medicalConditions',
    title: 'Any serious medical conditions?',
    description:
      'We do our best to make your personal plan completely safe and injury-free',
    variants: [
      'Diabetes',
      'High blood pressure',
      'High cholesterol',
      'Other',
      'None of above',
    ],
  },
  {
    name: 'medicationsListed',
    title: 'Are you taking any of the medications listed?',
    description:
      'We want your plan to be safe and fit perfectly into your lifestyle',
    variants: [
      'Vitamins',
      'Hormonal',
      'Antibiotics',
      'Other',
      'I don’t accept anything',
    ],
    icons: [
      'quest_icon_vit_abde',
      'quest_icon_vit_chemistry',
      'quest_icon_vit_antibio',
      'quest_icon_vit_other',
      'quest_icon_vit_dont',
    ],
  },
  {
    name: 'practicedFasting',
    title: 'Have you practiced fasting before?',
    description: 'We will adjust the plan depending on your experience',
    variants: [
      'Consider me a beginner',
      'I have a basic knowledge',
      'I am well-experienced',
    ],
  },
  {
    name: 'mealEasierSkip',
    title: 'What meal is easier to skip for you?',
    description: 'We will adjust the plan depending on your habits',
    variants: ['Breakfast', 'Lunch', 'Dinner'],
    icons: ['quest_icon_breakfast', 'quest_icon_lunch', 'quest_icon_dinner'],
  },
  {
    name: 'whenWasIdealWeight',
    title: 'When was the last time you were at your ideal weight?',
    description:
      'No worries, the plan we design for you will help you get back to your goal weight',
    variants: [
      'Less than a year ago',
      '1 to 2 years ago',
      'More than 3 years ago',
      'Never',
    ],
  },
  {
    name: 'lifeEventsWeightGain',
    title:
      'Have you had any life events led to weight gain in the last few years?',
    variants: [
      'Stress or mental health',
      'Busier work & family life',
      'Marriage or relationship',
      'Medical or hormonal disorder',
      'None of adove',
    ],
  },
  {
    name: 'importantEvent',
    title: 'Do you have an important event coming up?',
    description:
      'Having something to look forward to can be a big motivator to stick to your plan',
    variants: [
      'Vacation',
      'Wedding',
      'Summer',
      'Sports competition',
      'Other',
      'Don’t have any event',
    ],
    icons: [
      'quest_icon_vacation',
      'quest_icon_wedding',
      'quest_icon_summer',
      'quest_icon_sports',
      'quest_icon_other',
      'quest_icon_dontevent',
    ],
  },
  {
    name: 'intermittentFasting',
    title: 'Intermittent fasting suits you!',
    markedTitle: 'Intermittent fasting suits you!',
    text: 'Our recipes are simple to cook and meal plans are just perfect, so it’s much easier to stick into rather then other diets',
    text2: 'Enjoy your food and burn fat without calorie counting 🍀 ',
    withImage: 'quest_img_intermitted',
  },
  {
    name: 'lifestyle',
    title: 'What is your lifestyle?',
    description:
      'Knowing details about your lifestyle helps us come up with the plan that suits you the best',
    variants: [
      'No active',
      'Some activity',
      'Moderately active',
      'Very active',
    ],
    variantsDescription: [
      'Sedentary lifestyle and no or episodic exercise',
      'Low intensity exercise (walking, bicycle, swimming, yoga) 1-2 times a week',
      'Regular walks, moderate intensity exercises 3-4 times a week',
      'High intensity exercises or hard physical work 5-7 times a week',
    ],
    icons: [
      'quest_icon_noactive',
      'quest_icon_lowactive',
      'quest_icon_regularactive',
      'quest_icon_highactive',
    ],
  },
  {
    name: 'dailyWaterIntake',
    title: 'What is your daily water intake?',
    description:
      'Drinking enough water is essential for a healthy and fit body',
    variants: [
      'I only have coffee or tea',
      'About 2 glasses (16 oz)',
      'About 2-6 glasses (16-48 oz)',
      'More than 6 glasses',
    ],
  },
  {
    name: 'HowMuchSleep',
    title: 'How much do you sleep?',
    description:
      'Let us know if you need any adjustments in your sleep schedule',
    variants: [
      'Minimal rest (less than 5 hours)',
      'I get some shut-eye (5-6 hours)',
      'I sleep long and well (7-8 hours)',
      'I’m a sleep superhero (more than 8 hours)',
    ],
    icons: [
      'quest_icon_minrest',
      'quest_icon_somerest',
      'quest_icon_sleeplong',
      'quest_icon_sleephero',
    ],
  },
  {
    name: 'additionalGoal',
    title: 'Are there other goal we can help you with?',
    description: 'Choose as many options as you want',
    variants: [
      'Make my skin look younger',
      'Reduce stress',
      'Boost my immunity',
      'Improve your concentration',
      'Purify the body',
      'Sleep better',
    ],
    icons: [
      'quest_icon_skinlook',
      'quest_icon_reducestress',
      'quest_icon_immunity',
      'quest_icon_concentration',
      'quest_icon_purify',
      'quest_icon_sleepbetter',
    ],
    nextButton: true,
  },
  {
    name: 'whenResults',
    title: 'When do you want to see the results?',
    variants: ['weeks', 'weeks', 'weeks', 'weeks', 'weeks', 'weeks', 'weeks'],
    variantsDescription: [
      ' / week',
      ' / week',
      ' / week',
      ' / week',
      ' / week',
      ' / week',
      ' / week',
    ],
    variantsValuesKg: [0.5, 0.6, 0.7, 0.8, 0.9, 1.1, 1.3],
    variantsValuesLbs: [1.1, 1.3, 1.5, 1.8, 2.0, 2.4, 2.9],
    variantsDifficulty: [
      'Easy',
      'Medium',
      'Hard',
      'Hard',
      'Challenge',
      'Challenge',
      'Challenge',
    ],
  },
  {
    name: 'timeFirstMeal',
    title: 'What time do you eat your first meal?',
    description: 'Time is needed to create the perfect plan for you',
    nextButton: true,
    timeFirstMeal: 'true',
  },
  {
    name: 'timeLastMeal',
    title: 'What time do you eat your last meal?',
    description: 'Time is needed to create the perfect plan for you',
    nextButton: true,
    timeLastMeal: 'true',
  },
];
