/* eslint-disable react-hooks/exhaustive-deps */
import s from './Login.module.scss';
import { useNavigate } from 'react-router-dom';
import Title from '../../components/Title/Title';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import lock from '../../img/loginPage/lock.png';
import google from '../../img/loginPage/google.png';
import apple from '../../img/loginPage/apple.png';
import tiktok from '../../img/loginPage/tiktok.png';
import facebook from '../../img/loginPage/facebook.png';

import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import React, { useEffect, useState } from 'react';
import ReactPixel from "react-facebook-pixel";

const Login = () => {
  const navigate = useNavigate();
  const [appleSignInData, setAppleSignInData] = useState(null);

  const saveJwtToLocalStorage = jwt => {
    localStorage.setItem('jwt', jwt);
  };

  const fetchDataFromLocalStorage = () => {
    const dataString = localStorage.getItem('userData');
    if (!dataString) {
      return {};
    }

    return JSON.parse(dataString);
  };

  const loadedAnswers = JSON.parse(localStorage.getItem('answers'));
  console.log('loadedAnswers:', loadedAnswers);

  const returnToResult = () => {
    navigate('/result');
  };

  const patchUserProfile = async jwt => {
    const userData = fetchDataFromLocalStorage();

    try {
      const patchRes = await fetch(
        `${process.env.REACT_APP_API_LINK}/v1/user`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify(userData),
        }
      );

      if (patchRes.ok) {
        console.log('User profile updated successfully');
      } else {
        console.error('Error updating user profile');
      }
    } catch (patchError) {
      console.error('Error updating user profile:', patchError);
    }
  };

  const signInWithApple = async () => {
    if (appleSignInData) {
      saveJwtToLocalStorage(appleSignInData.jwt);
      navigate('/paywall');
      return;
    }

    window.AppleID.auth.init({
      clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
      scope: 'name email',
      redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URL,
      state: '[state]',
      nonce: '[NONCE]',
      usePopup: true,
    });

    try {
      const token = await window.AppleID.auth.signIn();
      const responsePayload = token.authorization;

      const metaTag = document.querySelector(
        `meta[name='appleid-signin-client-id']`
      );

      const res = await fetch(
        `${process.env.REACT_APP_API_LINK}/v1/auth/apple`,
        {
          method: 'POST',
          body: JSON.stringify({
            idToken: responsePayload.id_token,
            nonce: responsePayload.nonce,
            audience: metaTag.content,
            fullName:
              responsePayload.user &&
              responsePayload.user.name &&
              (responsePayload.user.name.firstName ||
                responsePayload.user.name.lastName)
                ? [
                  responsePayload.user.name.firstName,
                  responsePayload.user.name.lastName,
                ]
                  .filter(e => e)
                  .join(' ')
                : null,
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      if (res.ok) {
        const resDataJwt = await res.json();
        console.log('Logged in with Apple');
        ReactPixel.track('CompleteRegistration');
        window.fbq('CompleteRegistration');
        saveJwtToLocalStorage(resDataJwt.jwt);
        setAppleSignInData(resDataJwt);
        patchUserProfile(resDataJwt.jwt);
        navigate('/paywall');
      } else {
        console.error('Error with Apple Sign-In');
      }
    } catch (error) {
      console.error('Error with Apple Sign-In:', error);
    }
  };

  const signInWithFacebook = async response => {
    try {
      if (!window.FB) {
        console.error('Facebook SDK not initialized');
        return;
      }

      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v11.0',
      });

      const res = await fetch(
        `${process.env.REACT_APP_API_LINK}/v1/auth/facebook`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            idToken: response.accessToken,
          }),
        }
      );

      if (res.ok) {
        const resDataJwt = await res.json();
        ReactPixel.track('CompleteRegistration');
        window.fbq('CompleteRegistration');
        saveJwtToLocalStorage(resDataJwt.jwt);
        patchUserProfile(resDataJwt.jwt);
        navigate('/paywall');
      } else {
        console.error('Error with Facebook Sign-In');
      }
    } catch (error) {
      console.error('Error with Facebook Sign-In:', error);
    }
  };

  const signInWithTikTok = async () => {
    const clientId = process.env.REACT_APP_TIKTOK_CLIENT_KEY;
    console.log('clientId:', clientId);
    const redirectURI = process.env.REACT_APP_TIKTOK_REDIRECT_URI;
    console.log('redirectURI:', redirectURI);

    const state = Math.random().toString(36).substring(7);
    sessionStorage.setItem('tiktok_auth_state', state);

    window.location.href = `https://www.tiktok.com/v2/auth/authorize/?client_key=${encodeURIComponent(
      clientId
    )}&scope=user.info.basic&response_type=code&redirect_uri=${encodeURIComponent(
      redirectURI
    )}&state=${encodeURIComponent(state)}`;
  };

  const handleTikTokRedirect = async () => {
    console.log('handleTikTokRedirect:');
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');

    if (!code || !state) {
      console.log('No code or state found in query parameters');
      return;
    }

    try {
      if (
        state === sessionStorage.getItem('tiktok_auth_state') &&
        code !== null
      ) {
        const res = await fetch(
          `${process.env.REACT_APP_API_LINK}/v1/auth/tiktok`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              code: code,
              redirectUri: process.env.REACT_APP_TIKTOK_REDIRECT_URI,
            }),
          }
        );

        if (res.ok) {
          const resDataJwt = await res.json();
          console.log('resDataJwt:', resDataJwt);
          ReactPixel.track('CompleteRegistration');
          window.fbq('CompleteRegistration');
          saveJwtToLocalStorage(resDataJwt.jwt);
          sessionStorage.setItem('tiktok_access_token', resDataJwt.jwt);
          patchUserProfile(resDataJwt.jwt);
          navigate('/paywall');
        } else {
          console.error('Error with TikTok Sign-In');
        }
      }
    } catch (error) {
      console.error('Error with TikTok Sign-In:', error);
    }
  };

  useEffect(() => {
    handleTikTokRedirect();
  }, []);

  return (
    <div className={s.container}>
      <ProgressBar percentage={100} stepBack={returnToResult}/>
      <div className={s.login}>
        <div>
          <Title
            fullText="Continue your Fasting journey with Fasting app"
            markedText={['your Fasting journey']}
            markedColor={'#8C8AFF'}
          />
          <div className={s.description}>
            You’ll have access to your personal plan in the app
          </div>

          <button className={s.login_button} onClick={() => signInWithApple()}>
            <img src={apple} alt="Continue with Apple"/> Continue with Apple
          </button>
          <button className={s.login_button}>
            <div className={s.GoogleLoginButton}>
              <GoogleLogin
                onSuccess={async credentialResponse => {
                  console.log(credentialResponse);
                  const idToken = credentialResponse.credential;
                  try {
                    const res = await fetch(
                      `${process.env.REACT_APP_API_LINK}/v1/auth/google`,
                      {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          idToken: idToken,
                        }),
                      }
                    );

                    if (res.ok) {
                      const resDataJwt = await res.json();
                      ReactPixel.track('CompleteRegistration');
                      window.fbq('CompleteRegistration');
                      saveJwtToLocalStorage(resDataJwt.jwt);
                      patchUserProfile(resDataJwt.jwt);
                      navigate('/paywall');
                    } else {
                      console.log('Error');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </div>
            <img src={google} alt="Continue with Google"/> Continue with Google
          </button>
          <button className={s.login_button} onClick={signInWithTikTok}>
            <img src={tiktok} alt="Continue with TikTok"/> Continue with TikTok
          </button>
          <div className={s.login_button}>
            <div className={s.GoogleLoginButton}>
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                fields="name,email,picture"
                callback={signInWithFacebook}
                cssClass={s.facebookButton}
                textButton="Continue with Facebook"
                icon={<img src={facebook} alt="Continue with Facebook"/>}
              />
            </div>
            <img src={facebook} alt="Continue with Facebook"/> Continue with
            Facebook
          </div>
        </div>

        <p className={s.privacy}>
          <img src={lock} alt="lock"/>
          Your data is 100% secure. <br/>
          We do not share your personal <br/>
          information with third parties.
        </p>
      </div>
    </div>
  );
};

export default Login;
