import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './fonts/Gilroy-ExtraBold.ttf';
import './fonts/Gilroy-Heavy.ttf';
import './fonts/Gilroy-SemiBold.ttf';
import './fonts/Gilroy-Light.ttf';
import './fonts/Gilroy-Regular.ttf';
import './fonts/Gilroy-Medium.ttf';
import './fonts/Gilroy-UltraLight.ttf';
import './fonts/Gilroy-Bold.ttf';
import './fonts/Gilroy-Thin.ttf';
import './fonts/Gilroy-Black.ttf';

import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  </BrowserRouter>
);
